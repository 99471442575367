<script>
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  lineChartDailyCreatedPosStations,
  lineChartMonthlyCreatedPosStations,
  lineChartDailyCreatedPosHubs,
  lineChartMonthlyCreatedPosHubs,
  lineChartDailyCreatedPosManagers,
  lineChartMonthlyCreatedPosManagers
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  page: {
    title: "Apex Chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { PageHeader },
  data() {
    return {
      lineChartDailyCreatedPosStations: lineChartDailyCreatedPosStations,
      lineChartMonthlyCreatedPosStations: lineChartMonthlyCreatedPosStations,
      lineChartDailyCreatedPosHubs: lineChartDailyCreatedPosHubs,
      lineChartMonthlyCreatedPosHubs: lineChartMonthlyCreatedPosHubs,
      lineChartDailyCreatedPosManagers: lineChartDailyCreatedPosManagers,
      lineChartMonthlyCreatedPosManagers: lineChartMonthlyCreatedPosManagers,
      pos_stations_daily_charts: [],
      pos_stations_monthly_charts: [],
      pos_hubs_daily_charts: [],
      pos_hubs_monthly_charts: [],
      pos_managers_daily_charts: [],
      pos_managers_monthly_charts: [],

      title: "Apex charts",
      items: [
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "Apex charts",
          active: true
        }
      ]
    };
  },
  methods: {
   
  },
  async mounted() {
    
  },
};
</script>

<template>
  <PageHeader :title="this.$t('charts.charts')" />
    
  

  <!-- end row -->

  <!-- end row -->
</template>